import React, { Fragment, ReactNode } from 'react';

import { FiltersPopoverTypesEnum } from '../../../../../types';

import { SearchFilterNanoID } from '../../../../searchFilters/searchFiltersTypes';

import { useIndexPageFiltersPopoverState } from '../../../../common/hooks/useIndexPageFiltersPopoverState';

import { SearchFiltersPopover } from '../../../../searchFilters/components/SearchFiltersPopover';

import { SearchFiltersScopes } from '../../../../searchFilters/searchFiltersConstants';

interface IndexPagePopoverProps {
  searchFilterNanoId?: SearchFilterNanoID;
  filtersPopover: ReactNode;
  popoverTargetId?: string;
  scope?: SearchFiltersScopes;
  withSearchFiltersPopover?: boolean;
  onDeleteActiveFilter?: () => void;
}

function IndexPagePopover({
  searchFilterNanoId,
  filtersPopover,
  popoverTargetId,
  scope,
  onDeleteActiveFilter,
  withSearchFiltersPopover = false
}: IndexPagePopoverProps) {
  const { filtersPopoverType } = useIndexPageFiltersPopoverState();

  switch (filtersPopoverType) {
    case FiltersPopoverTypesEnum.ACTIVE_FILTERS:
      return <Fragment>{filtersPopover}</Fragment>;
    case FiltersPopoverTypesEnum.SAVED_FILTERS:
      return withSearchFiltersPopover ? (
        <SearchFiltersPopover
          activeFilterNanoId={searchFilterNanoId}
          onDeleteActiveFilter={onDeleteActiveFilter}
          popoverTargetId={popoverTargetId}
          scope={scope}
        />
      ) : null;
    default:
      return null;
  }
}
export default IndexPagePopover;
