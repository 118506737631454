import { Control, Controller, FieldPath } from 'react-hook-form';

import {
  RangeInputFieldControl,
  RangeInputValueType
} from './components/RangeInputFieldControl';
import { I18nText } from '../../../types';

interface RangeInputFieldProps<FormDataType> {
  control: Control<FormDataType>;
  i18nFromPlaceholder?: I18nText;
  i18nToPlaceholder?: I18nText;
  name: FieldPath<FormDataType>;
  type?: string;
}

function RangeInputField<FormDataType>({
  control,
  i18nFromPlaceholder,
  i18nToPlaceholder,
  name,
  type
}: RangeInputFieldProps<FormDataType>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <RangeInputFieldControl
          error={error}
          i18nFromPlaceholder={i18nFromPlaceholder}
          i18nToPlaceholder={i18nToPlaceholder}
          name={name}
          onChange={onChange}
          value={value as RangeInputValueType}
          type={type}
        />
      )}
    />
  );
}

export default RangeInputField;
