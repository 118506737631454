import { useCallback } from 'react';
import cl from 'classnames';

import { HexColor } from '../../../../types';
import { IconsEnum } from '../../../../assets/icons/types';
import { SearchFilterNanoID } from '../../searchFiltersTypes';

import { useDeleteSearchFilter } from '../../hooks/useDeleteSearchFilter';

import { ConfirmModalButton } from '../../../../helpers/buttons/ConfirmModalButton';
import { Icon } from '../../../../helpers/Icon';
import { NextPureLinkHelper } from '../../../../helpers/links/NextPureLinkHelper';

import { DELETE_SEARCH_FILTER_QUERY } from '../../queries/deleteSearchFilter.query';

import { CategoryPath } from '../../../categories/CategoryPath';
import { MessagePath } from '../../../messages/MessagePath';
import { PagePath } from '../../../pages/PagePath';
import { PermissionPath } from '../../../permissions/PermissionPath';
import { ProjectPath } from '../../../projects/ProjectPath';
import { RolePath } from '../../../roles/RolePath';
import { SearchFiltersScopes } from '../../searchFiltersConstants';
import { SentMessagePath } from '../../../sentMessages/SentMessagePath';
import { TaskPath } from '../../../tasks/TaskPath';
import { UserPath } from '../../../users/UserPath';

function searchFilterHref(
  searchFilterNanoId: SearchFilterNanoID,
  scope: SearchFiltersScopes
) {
  switch (scope) {
    case SearchFiltersScopes.CATEGORIES_INDEX:
      return CategoryPath.filters(searchFilterNanoId);
    case SearchFiltersScopes.MESSAGES_INDEX:
      return MessagePath.filters(searchFilterNanoId);
    case SearchFiltersScopes.PAGES_INDEX:
      return PagePath.filters(searchFilterNanoId);
    case SearchFiltersScopes.PERMISSIONS_INDEX:
      return PermissionPath.filters(searchFilterNanoId);
    case SearchFiltersScopes.PROJECTS_INDEX:
      return ProjectPath.filters(searchFilterNanoId);
    case SearchFiltersScopes.ROLES_INDEX:
      return RolePath.filters(searchFilterNanoId);
    case SearchFiltersScopes.SENT_MESSAGES_INDEX:
      return SentMessagePath.filters(searchFilterNanoId);
    case SearchFiltersScopes.TASKS_INDEX:
      return TaskPath.filters(searchFilterNanoId);
    case SearchFiltersScopes.WORKERS_INDEX:
      return UserPath.workersFilters(searchFilterNanoId);
    case SearchFiltersScopes.CLIENTS_INDEX:
      return UserPath.clientsFilters(searchFilterNanoId);
    default:
      return null;
  }
}

interface SearchFiltersListItemProps {
  active: boolean;
  color?: HexColor;
  i18nText: string;
  isPopover?: boolean;
  onDeleteActiveFilter?: () => void;
  onSelectItem?: () => void;
  scope: SearchFiltersScopes;
  searchFilterNanoId: SearchFilterNanoID;
  toggleIsPopoverFixed?: () => void;
}

function SearchFiltersListItem({
  active,
  color,
  i18nText,
  isPopover,
  onDeleteActiveFilter,
  onSelectItem,
  scope,
  searchFilterNanoId,
  toggleIsPopoverFixed
}: SearchFiltersListItemProps) {
  const {
    deleteSearchFilter,
    deleteSearchFilterLoading,
    deleteSearchFilterErrorMessage,
    deleteSearchFilterReset
  } = useDeleteSearchFilter({ query: DELETE_SEARCH_FILTER_QUERY });

  const handleDeleteFilter = useCallback<() => Promise<void>>(
    () =>
      deleteSearchFilter({ uuid: searchFilterNanoId }).then(() => {
        if (active) {
          onDeleteActiveFilter?.();
        }
      }),
    [active, deleteSearchFilter, searchFilterNanoId, onDeleteActiveFilter]
  );

  const handleCloseConfirmModal = useCallback<() => void>(() => {
    toggleIsPopoverFixed?.();
    deleteSearchFilterReset();
  }, [toggleIsPopoverFixed, deleteSearchFilterReset]);

  const colorMarker = color ? (
    <div className="flex items-center" style={{ color: color }}>
      <Icon icon={IconsEnum.LABEL_SOLID} />
    </div>
  ) : null;

  const emptyMarker = (
    <div className="flex items-center" style={{ color: '#80808059' }}>
      <Icon icon={IconsEnum.LABEL_OUTLINE} />
    </div>
  );
  return (
    <li
      className={cl(
        'bg-gray-500 bg-opacity-0 dark:focus-within:ring-gray-100 duration-150 ease-in-out flex focus-within:ring-2 focus-within:ring-gray-850 group hover:bg-opacity-20    items-center py-2 relative transition-colors',
        isPopover ? '-mx-3 px-2 rounded-md' : '-mx-4 pl-8 pr-4',
        active
          ? 'bg-opacity-25 hover:bg-opacity-30'
          : 'bg-opacity-0 hover:bg-opacity-20'
      )}
    >
      {isPopover ? colorMarker || emptyMarker : null}

      <NextPureLinkHelper
        className="focus:outline-none flex-1 px-2 text-sm truncate"
        href={searchFilterHref(searchFilterNanoId, scope)}
        i18nText={i18nText}
        onClick={onSelectItem}
      />

      {isPopover ? null : colorMarker}

      <ConfirmModalButton
        className="p-2 -mr-2 -my-2 sm:absolute sm:opacity-0 text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 focus:ring-offset-0 group-hover:relative group-hover:opacity-100 focus:relative focus:opacity-100 rounded-full z-10"
        errorMessage={deleteSearchFilterErrorMessage}
        i18nSubmitText="words.delete"
        i18nText="messages.are_you_sure_you_want_to_delete_saved_filter_this_action_cannot_be_undone"
        i18nTitle="words.delete_filter"
        icon={IconsEnum.X}
        id="delete-saved-filter-modal-button"
        isLoading={deleteSearchFilterLoading}
        modalIcon={IconsEnum.EXCLAMATION}
        modalIconClassName="h-6 w-6 text-red-600"
        onClose={handleCloseConfirmModal}
        onOpen={toggleIsPopoverFixed}
        onSubmit={handleDeleteFilter}
      />
    </li>
  );
}

export default SearchFiltersListItem;
