import format from 'date-fns/format';

import { dateFnsLocales } from '../dateFnsLocales';

const formatOptions = { locale: dateFnsLocales.getLocale('en') };

const toDate = (date: string): string =>
  format(new Date(date), 'yyyy-MM-dd', formatOptions);

const toDateTimeLocal = (date: string): string =>
  format(new Date(date), "yyyy-MM-dd'T'HH:mm", formatOptions);

const toDateTimeWithTimezone = (date: string): string =>
  format(new Date(date), "yyyy-MM-dd'T'HH:mm:ssxxx", formatOptions);

export default { toDateTimeLocal, toDateTimeWithTimezone, toDate };
