import React from 'react';

import { ErrorMessage, I18nText } from '../../../types';

import { IconsEnum } from '../../../assets/icons/types';

import { ModalOptions } from '../../modals/hooks/useModal';

import { AlertMessage } from '../../AlertMessage';
import { Translate } from '../../Translate';

import {
  SimpleModalButtonDefaultProps,
  SimpleModalButton
} from '../SimpleModalButton';

interface ConfirmModalButtonProps {
  errorMessage?: ErrorMessage;
  i18nButtonText?: I18nText;
}

function ConfirmModalButton({
  afterSubmit,
  className,
  disabled,
  errorMessage,
  i18nButtonText,
  i18nSubmitText,
  i18nCancelText,
  i18nText,
  i18nTitle,
  icon,
  iconClassName,
  id,
  isLoading,
  modalIcon,
  modalIconClassName,
  onClose,
  onOpen,
  onSubmit,
  submitButtonColor = 'red',
  tooltipI18nText
}: ConfirmModalButtonProps & ModalOptions & SimpleModalButtonDefaultProps) {
  return (
    <SimpleModalButton
      afterSubmit={afterSubmit}
      className={className || 'text-red-500 underline text-sm'}
      disabled={disabled}
      i18nSubmitText={i18nSubmitText}
      i18nCancelText={i18nCancelText}
      i18nText={i18nButtonText}
      i18nTitle={i18nTitle}
      icon={icon}
      iconClassName={iconClassName}
      id={id}
      isLoading={isLoading}
      modalIcon={modalIcon || IconsEnum.EXCLAMATION}
      modalIconClassName={modalIconClassName || 'h-6 w-6 text-red-600'}
      submitButtonColor={submitButtonColor}
      tooltipI18nText={tooltipI18nText}
      onClose={onClose}
      onOpen={onOpen}
      onSubmit={onSubmit}
    >
      <div className="mt-2">
        <p className="text-sm text-gray-500 dark:text-gray-400">
          {i18nText ? <Translate id={i18nText} /> : undefined}
        </p>
      </div>

      <div className="mt-2">
        <AlertMessage message={errorMessage} />
      </div>
    </SimpleModalButton>
  );
}

export default ConfirmModalButton;
