import {
  ID,
  UUID,
  CreatedAt,
  UpdatedAt,
  FetchItemsGqlQuery,
  FetchItemGqlQuery,
  CreateItemGqlQuery,
  UpdateItemGqlQuery,
  DeleteItemGqlQuery,
  CreateItemGqlStatus,
  UpdateItemGqlStatus,
  DeleteItemGqlStatus,
  ItemGqlError,
  FetchItemsCacheKey,
  FetchItemCacheKey,
  FetchItemsFetched,
  FetchItemsIsPlaceholderData,
  FetchItemsErrorMessage,
  FetchItemsPage,
  FetchItemsLimit,
  FetchItemsSort,
  FetchItemsTotalCount,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsSortItems,
  FetchItemsShowItem,
  FetchItemsEditItem,
  HexColor,
  ModelBrand,
  NanoID
} from '../../types';
import { CreateSearchFilterInputType } from './hooks/useCreateSearchFilter';

export type SearchFilterID = ID;
export type SearchFilterUUID = UUID;
export type SearchFilterNanoID = ModelBrand<NanoID, 'SearchFilterNanoID'>;
export type SearchFilterName = string;
export type SearchFilterCreatedAt = CreatedAt;
export type SearchFilterUpdatedAt = UpdatedAt;
export type SearchFilterScope = string;
export type SearchFilterData = JSON;
export type SearchFilterColors = HexColor[];

export const enum SearchFilterFields {
  UUID = 'uuid',
  NANO_ID = 'nano_id',
  NAME = 'name',
  COLORS = 'colors'
}

export interface FetchSearchFiltersFilters {
  scope?: {
    eq?: string;
  };
  self?: boolean;
  createdAt?: string;
}

export enum FetchSearchFiltersSortTypes {
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FetchSearchFiltersGqlQuery = FetchItemsGqlQuery;
export type FetchSearchFilterGqlQuery = FetchItemGqlQuery;
export type CreateSearchFilterGqlQuery = CreateItemGqlQuery;
export type UpdateSearchFilterGqlQuery = UpdateItemGqlQuery;
export type DeleteSearchFilterGqlQuery = DeleteItemGqlQuery;

export type CreateSearchFilterGqlStatus = CreateItemGqlStatus;
export type UpdateSearchFilterGqlStatus = UpdateItemGqlStatus;
export type DeleteSearchFilterGqlStatus = DeleteItemGqlStatus;
export type SearchFilterGqlError = ItemGqlError;

export type FetchSearchFiltersCacheKey = FetchItemsCacheKey;
export type FetchSearchFilterCacheKey = FetchItemCacheKey;

export type FetchSearchFiltersFetched = FetchItemsFetched;
export type FetchSearchFiltersErrorMessage = FetchItemsErrorMessage;
export type FetchSearchFiltersIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchSearchFiltersPage = FetchItemsPage;
export type FetchSearchFiltersLimit = FetchItemsLimit;
export type FetchSearchFiltersSort = FetchItemsSort;
export type FetchSearchFiltersTotalCount = FetchItemsTotalCount;
export type FetchSearchFiltersSortSearchFilters = FetchItemsSortItems;
export type FetchSearchFiltersPaginateSearchFilters = FetchItemsPaginateItems;
export type FetchSearchFiltersPrefetchSearchFilters = FetchItemsPrefetchItems;
export type FetchSearchFiltersShowSearchFilter = FetchItemsShowItem;
export type FetchSearchFiltersEditSearchFilter = FetchItemsEditItem;

export type CreateSearchFilterFunc<FiltersType> = (
  values: CreateSearchFilterInputType<FiltersType>
) => Promise<void>;
