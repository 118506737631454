import { useCallback, useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import includes from 'lodash/includes';

import {
  FetchItemsClearItemsFilters,
  FetchItemsFilterItems,
  FiltersPopoverTypesEnum
} from '../../../../types';

import {
  CreateSearchFilterFunc,
  SearchFilterNanoID
} from '../../../searchFilters/searchFiltersTypes';

import {
  CREATE_SEARCH_FILTER_QUERY,
  CreateSearchFilterQueryResponse
} from '../../../searchFilters/queries/createSearchFilter.query';

import { useIndexPageFiltersPopoverState } from '../useIndexPageFiltersPopoverState';
import { useCreateSearchFilter } from '../../../searchFilters/hooks/useCreateSearchFilter';

export interface IndexPageFiltersPopoverOptions<FiltersType> {
  onClosePopover?: () => void;
  onCreateSearchFilter?: (searchFilterNanoId: SearchFilterNanoID) => void;
  onFiltersChange?: FetchItemsFilterItems<FiltersType>;
  onFiltersClear?: FetchItemsClearItemsFilters;
  onResetFilters?: () => void;
  popoverType: FiltersPopoverTypesEnum;
  targetId?: string;
}

function useIndexPageFiltersPopover<FiltersType>({
  onClosePopover,
  onCreateSearchFilter,
  onFiltersChange,
  onFiltersClear,
  onResetFilters,
  popoverType,
  targetId
}: IndexPageFiltersPopoverOptions<FiltersType>) {
  const { filtersPopoverType, setFiltersPopoverType } =
    useIndexPageFiltersPopoverState();

  const [isPopoverFixed, setIsPopoverFixed] = useState<boolean>(false);

  const handleClosePopover = useCallback<() => void>(() => {
    setFiltersPopoverType(FiltersPopoverTypesEnum.NONE);
    onClosePopover?.();
  }, [onClosePopover, setFiltersPopoverType]);

  const handleToggleIsPopoverFixed = useCallback<() => void>(
    () => setIsPopoverFixed((prevVal) => !prevVal),
    [setIsPopoverFixed]
  );

  const popoverDivRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (e) => {
      const element = popoverDivRef.current;

      if (
        !isPopoverFixed &&
        element &&
        !element.contains(e.target) &&
        !includes(e.target.id, targetId)
      ) {
        handleClosePopover();
      }
    },
    [handleClosePopover, isPopoverFixed, targetId]
  );

  useEffect(() => {
    if (filtersPopoverType === popoverType) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popoverType, handleClickOutside, filtersPopoverType]);

  const { createSearchFilter, createSearchFilterLoading } =
    useCreateSearchFilter<FiltersType, CreateSearchFilterQueryResponse>({
      query: CREATE_SEARCH_FILTER_QUERY
    });

  const handleFiltersSave = useCallback<CreateSearchFilterFunc<FiltersType>>(
    (values) =>
      createSearchFilter?.(values)
        .then((result) => {
          onCreateSearchFilter?.(result?.createSearchFilter?.recordNanoId);
          handleClosePopover();
        })
        .catch((e) => {
          console.log(get(e, 'message'));
        }),
    [createSearchFilter, handleClosePopover, onCreateSearchFilter]
  );

  const handleFiltersChange = useCallback<FetchItemsFilterItems<FiltersType>>(
    (values: FiltersType) => {
      onFiltersChange?.(values);
      handleClosePopover();
    },
    [onFiltersChange, handleClosePopover]
  );

  const handleFiltersClear = useCallback<FetchItemsClearItemsFilters>(() => {
    onFiltersClear?.();
    handleClosePopover();
    onResetFilters?.();
  }, [onFiltersClear, handleClosePopover, onResetFilters]);

  return {
    filtersSaving: createSearchFilterLoading,
    handleClosePopover,
    handleFiltersChange,
    handleFiltersClear,
    handleFiltersSave,
    handleToggleIsPopoverFixed,
    popoverDivRef
  };
}

export default useIndexPageFiltersPopover;
