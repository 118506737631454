import { useCallback, useEffect, useState } from 'react';
import { DeepPartial, UnpackNestedValue } from 'react-hook-form';
import omit from 'lodash/omit';

import { FetchItemsClearItemsFilters } from '../../../../types';

import { useReactHookForm } from '../base/useReactHookForm';

import { isEveryPropEmpty } from '../../../../utils/isEveryPropEmpty';

const saveFiltersFormFields = ['name', 'colors'];

interface IndexPageFiltersFormOptions<FormDataType> {
  defaultValues: UnpackNestedValue<DeepPartial<FormDataType>>;
  onClear: FetchItemsClearItemsFilters;
}

function useIndexPageFiltersForm<FormDataType>({
  defaultValues,
  onClear
}: IndexPageFiltersFormOptions<FormDataType>) {
  const {
    control,
    errors,
    handleSubmitReactHookForm,
    register,
    setValue,
    watch
  } = useReactHookForm<FormDataType>({
    defaultValues
  });

  const [saveFiltersMode, setSaveFiltersMode] = useState<boolean>(false);

  const [isEditingUsers, setIsEditingUsers] = useState<boolean>(false);

  const [isEditingOtherUsers, setIsEditingOtherUsers] =
    useState<boolean>(false);

  const [isFormEmpty, setIsFormEmpty] = useState<boolean>(
    isEveryPropEmpty(omit(defaultValues, saveFiltersFormFields))
  );

  useEffect(() => {
    const subscription = watch((data) => {
      setIsFormEmpty(isEveryPropEmpty(omit(data, saveFiltersFormFields)));
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const toggleIsEditingUsers = useCallback<() => void>(
    () => setIsEditingUsers((prevState) => !prevState),
    [setIsEditingUsers]
  );

  const toggleIsEditingOtherUsers = useCallback<() => void>(
    () => setIsEditingOtherUsers((prevState) => !prevState),
    [setIsEditingOtherUsers]
  );

  const toggleSaveFiltersMode = useCallback<() => void>(
    () => setSaveFiltersMode((prevVal) => !prevVal),
    [setSaveFiltersMode]
  );

  let onCancel = onClear;

  let onGoBack = null;

  if (isEditingUsers) {
    onCancel = onGoBack = toggleIsEditingUsers;
  }

  if (isEditingOtherUsers) {
    onCancel = onGoBack = toggleIsEditingOtherUsers;
  }

  if (saveFiltersMode) {
    onCancel = onGoBack = toggleSaveFiltersMode;
  }

  return {
    control,
    errors,
    handleSubmitIndexPageFiltersForm: handleSubmitReactHookForm,
    isEditingUsers,
    isEditingOtherUsers,
    isFormEmpty,
    onCancel,
    onGoBack,
    register,
    saveFiltersMode,
    setValue,
    toggleIsEditingUsers,
    toggleIsEditingOtherUsers,
    toggleSaveFiltersMode
  };
}

export default useIndexPageFiltersForm;
