import { FetchSearchFiltersSortTypes } from './searchFiltersTypes';

export const INITIAL_SEARCH_FILTERS_FILTERS = {};
export const INITIAL_SEARCH_FILTERS_PAGE = 1;
export const INITIAL_SEARCH_FILTERS_SORT = [
  FetchSearchFiltersSortTypes.CREATED_AT_DESC
];
export const INITIAL_SEARCH_FILTERS_LIMIT = 50;

export enum SearchFiltersScopes {
  CATEGORIES_INDEX = 'CategoriesIndex',
  PERMISSIONS_INDEX = 'PermissionsIndex',
  PROJECTS_INDEX = 'ProjectsIndex',
  ROLES_INDEX = 'RolesIndex',
  PAGES_INDEX = 'PagesIndex',
  SENT_MESSAGES_INDEX = 'SentMessagesIndex',
  TASKS_INDEX = 'TasksIndex',
  BILLS_INDEX = 'BillsIndex',
  MESSAGES_INDEX = 'MessagesIndex',
  WORKERS_INDEX = 'WorkersIndex',
  CLIENTS_INDEX = 'ClientsIndex'
}

export const searchFiltersColorsSelectDataItems = [
  { value: '', color: 'rgba(0, 0, 0, 0)', label: 'No color' },
  { value: '#FF3333', color: '#FF3333', label: 'Red' },
  { value: '#FF791A', color: '#FF791A', label: 'Orange' },
  { value: '#FA3899', color: '#FA3899', label: 'Pink' },
  { value: '#961FAD', color: '#961FAD', label: 'Purple' },
  { value: '#2D56D2', color: '#2D56D2', label: 'Blue' },
  { value: '#72CBF8', color: '#72CBF8', label: 'Light blue' },
  { value: '#06E0E0', color: '#06E0E0', label: 'Aqua' },
  { value: '#34B268', color: '#34B268', label: 'Green' },
  { value: '#D6E87D', color: '#D6E87D', label: 'Lime' },
  { value: '#F2DF0D', color: '#F2DF0D', label: 'Yellow' },
  { value: '#8A5542', color: '#8A5542', label: 'Brown' },
  { value: '#8C8C8C', color: '#8C8C8C', label: 'Gray' }
];
